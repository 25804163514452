import { Card, Row, Col, Empty } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import { AddToCart, Loader, QuantitySelector } from "../../components";
import "./details-product.css";
import AuthContext from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const DetailsProduit = () => {
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [stockProductIn, setStockProductIn] = useState(0);
  const [productIn, setProductIn] = useState({});
  const { idProduit } = useParams();
  const { user, isLoadingAccessToken, accessTokenJwt } = useContext(AuthContext);
  const { getProductById, getStockProductById, stockProduct, productById } =
    useContext(MainContext);
  const [inputValue, setInputValue] = useState(1);
  const { t } = useTranslation();
  /** L'objet produit envoyé via la balise Link de react router dom (au click de l'utilisateur) */
  const { state } = useLocation();

  const getStockProduct = (qtyStock, qtyReserved) => {
    const qtyAvailable = qtyStock - qtyReserved;
    if (qtyAvailable <= 0) {
      return 0;
    }
    return parseInt(qtyAvailable);
  };

  /* Si state contient l'objet produit on s'en sert sinon on envoie une requete avec l'id du produit */
  useEffect(() => {
    if (state !== null && Object.keys(state).length) {
      setProductIn(state);
      setStockProductIn(getStockProduct(state.QteStock, state.QteReserve));
      setIsLoadingProducts(false);
    } else {
      if (idProduit && !isLoadingAccessToken) {
        getProductById(idProduit);
        getStockProductById(idProduit);
      }
    }
  }, [state, idProduit, accessTokenJwt]);

  useEffect(() => {
    if (!isLoadingAccessToken && state === null) {
      setProductIn(productById);
      setIsLoadingProducts(false);
    }
  }, [productById, accessTokenJwt]);

  return isLoadingProducts ? (
    <Loader />
  ) : productIn ? (
    <Card
      title={`${productIn.Intitule} | ${productIn.Reference}`}
      cover={
        <div className="details-product-image__container">
          <img
            className="details-product-image"
            alt={productIn.Intitule}
            src={`https://test.mnbsales.com/images/${productIn?.Reference}.webp`}
            // Mode développement => Evite la tentative de récupération de l'image en boucle
            // onError={(e) => {
            //   e.target.onerror = null;
            //   e.target.src = "/images/no-photo.webp";
            // }}
          />
        </div>
      }
      className="card-details-product">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={12} lg={8}>
          <div className="bloc-vertical-details-product">
            <div>{t("Marque")}</div>
            <div className="bloc-details-product">{productIn.Statistique1}</div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <div className="bloc-vertical-details-product">
            <div>{t("Couleur")}</div>
            <div className="bloc-details-product">
              {productIn.Intitule ? productIn.Intitule.split("-")[1] : ""}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <div className="bloc-vertical-details-product">
            <div>Stock</div>
            <div className="bloc-details-product">{stockProductIn}</div>
          </div>
        </Col>
        {/* Infos Nez, Branche, Calibre (Pas dispo pour l'instant) */}

        {/* <Col xs={24} sm={12} md={12} lg={8}>
          <div className="bloc-vertical-details-product">
            <div>Calibre</div>
            <div className="bloc-details-product">{productById[0].calibre}</div>
          </div>
        </Col> */}
        {/* <Col xs={24} sm={12} md={12} lg={8}>
          <div className="bloc-vertical-details-product">
            <div>Nez</div>
            <div className="bloc-details-product">{productById[0].nez}</div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <div className="bloc-vertical-details-product">
            <div>Branche</div>
            <div className="bloc-details-product">{productById[0].branche}</div>
          </div>
        </Col> */}
        {user?.role !== "guest" ? (
          <>
            <Col xs={0} sm={0} md={0} lg={0} xl={8} xxl={8}></Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <QuantitySelector
                productById={productIn}
                stockProduct={stockProductIn}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <div className="bloc-vertical-details-product">
                <AddToCart
                  productById={productIn}
                  inputValue={inputValue}
                  stockProduct={stockProductIn}
                />
              </div>
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>
    </Card>
  ) : (
    <Empty description={<span>Aucun produit</span>} />
  );
};

export default DetailsProduit;
