import React, { useContext, useEffect, useState } from "react";
import { Table, Empty, Descriptions, Space, Row, Col } from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
  CommentsOnCart,
  DatePickerCustom,
  DeleteProductInCart,
  SelectClientOnCart,
  SelectRepresentant,
  ValiderCommande,
} from "../../components";
import "./cart.css";
import AuthContext from "../../contexts/AuthContext";
import CartContext from "../../contexts/CartContext";
import MainContext from "../../contexts/MainContext";
import { useTranslation } from "react-i18next";
import { SaveOrderInIndexedDB } from "../../components/boutons";
import PriceContext from "../../contexts/PriceContext";

const CartPage = () => {
  const { user } = useContext(AuthContext);
  const { cart, detailsPanier, addOrRemoveOne, waitingOrder, isWaitingOrder } =
    useContext(CartContext);
  const { idDevise, categorieTarifaire } = useContext(PriceContext);
  const { viewportWidth, clients } = useContext(MainContext);
  const [totalCart, setTotalCart] = useState(0);
  const [comValue, setComValue] = useState("");
  const [clientValue, setClientValue] = useState("");
  const [client, setClient] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [commande, setCommande] = useState([]);
  const [initialInfoLoaded, setInitialInfoLoaded] = useState(false);
  const [selectedRep, setSelectedRep] = useState("");
  const [selectedRepObject, setSelectedRepObject] = useState(null);
  const { usRepresentants } = useContext(MainContext);

  const { t } = useTranslation();

  // useEffect(() => {
  //   console.log("order in db: ", waitingOrder);
  //   console.log("is waiting order: ", isWaitingOrder);
  //   console.log("initial infos loaded: ", initialInfoLoaded);
  // }, [waitingOrder, isWaitingOrder, initialInfoLoaded]);

  useEffect(() => {
    if (usRepresentants.length > 0) {
      const newSelectedRepObject = usRepresentants.find((rep) => rep.Id === selectedRep);
      setSelectedRepObject(newSelectedRepObject);
    } else {
      setSelectedRepObject(null);
    }
  }, [selectedRep]);

  useEffect(() => {
    console.log("user in cart: ", user);
  }, [user]);

  useEffect(() => {
    if (isWaitingOrder && waitingOrder?.document && !initialInfoLoaded) {
      const clientFromWaitingOrder = clients.filter(
        (client) => waitingOrder?.document?.NumeroTiers === client.NumeroTiers
      );
      setComValue(waitingOrder?.document?.InfosLibres[0]?.Value);
      setClientValue(waitingOrder?.document?.NumeroTiers);
      // setDeliveryDate(waitingOrder?.document?.DateLivraison)
      setCommande({
        totalCart: waitingOrder?.document?.TotalCart,
        commentaire: waitingOrder?.document?.InfosLibres[0]?.Value,
        clientId: waitingOrder?.document?.NumeroTiers,
        client: clientFromWaitingOrder,
        cart: cart,
      });
      setInitialInfoLoaded(true);
    }
  }, [isWaitingOrder, waitingOrder, initialInfoLoaded]);

  useEffect(() => {
    if (initialInfoLoaded || !isWaitingOrder) {
      setCommande((prevCommande) => ({
        totalCart: totalCart || prevCommande.totalCart,
        // commentaire: comValue || prevCommande.commentaire,
        // clientId: clientValue || prevCommande.commentaire,
        commentaire: comValue,
        clientId: clientValue,
        client: client || prevCommande.client,
        deliveryDate: deliveryDate?._d,
        cart: cart.length ? cart : prevCommande.cart,
      }));
    }
  }, [totalCart, comValue, clientValue, cart, client, deliveryDate, initialInfoLoaded]);

  useEffect(() => {
    console.log("commande: ", commande);
    console.log("waiting commande: ", isWaitingOrder);
  }, [commande]);

  useEffect(() => {
    getTotalCart(cart);
    console.log("cart", cart);
  }, [cart, categorieTarifaire]);

  const getTotalCart = (cart) => {
    const totalCartTmp = cart
      .map((produit) => {
        const prixVente =
          produit?.product?.CategoriesTarifaire?.[categorieTarifaire]?.IdDevise !== 0
            ? produit?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixDevise
            : produit?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixVente;
        return prixVente * produit.qty;
      })
      .reduce((prev, curr) => prev + curr, 0);
    setTotalCart(totalCartTmp);
  };

  const onRepChange = (value) => {
    setSelectedRep(value);
    console.log("selectedRep: ", value);
  };

  const handleCommentChange = (value) => {
    setComValue(value);
    console.log("comValue: ", value);
  };

  const handleClientChange = (value) => {
    setClientValue(value);
    console.log("clientId:", value);
  };

  const handleDeliveryDateChange = (date, dateString) => {
    // setDeliveryDateString(dateString);
    setDeliveryDate(date);
    console.log("deliveryDate: ", date, dateString);
  };

  const onMinusBtnClick = (qty, product) => {
    // console.log("bouton moins", qty);
    addOrRemoveOne("-", product, cart);
  };

  const onPlusBtnClick = (qty, product) => {
    // console.log("bouton plus", qty);
    addOrRemoveOne("+", product, cart);
  };

  const columns = [
    {
      title: "Ref",
      key: "action",
      responsive: ["md"],
      render: (produit) => {
        return <span>{produit?.product?.Reference}</span>;
      },
    },
    {
      title: t("Intitule produit"),
      key: "action",
      render: (produit) => {
        return <span>{produit?.product?.Intitule}</span>;
      },
    },
    {
      title: t("Couleur"),
      key: "couleur",
      responsive: ["md"],
      render: (produit) => {
        return <span>{produit?.product?.Intitule.split("-")[1]}</span>;
      },
    },
    {
      title: t("Prix"),
      key: "prixVente",
      responsive: ["md"],
      render: (produit) => {
        return (
          <span>
            {produit?.product?.CategoriesTarifaire?.[categorieTarifaire]?.IdDevise !== 0
              ? produit?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixDevise
              : produit?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixVente}
            {(idDevise && idDevise === 3) || (idDevise && idDevise === 14) ? "$" : "€"}
          </span>
        );
      },
    },
    {
      title: t("Qté"),
      key: "qty",
      dataIndex: "qty",
      width: 128,
      render: (qty, product) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}>
          <button className="qtyBtn" onClick={() => onMinusBtnClick(qty, product, cart)}>
            <MinusSquareOutlined className="icon-moins" />
          </button>
          <p style={{ margin: 0 }}>{qty}</p>
          <button className="qtyBtn" onClick={() => onPlusBtnClick(qty, product)}>
            <PlusSquareOutlined className="icon-plus" />
          </button>
        </div>
      ),
    },
    {
      title: viewportWidth < 400 ? t("Supp") : t("Supprimer"),
      key: "action",
      width: 64,
      render: (produit) => {
        return (
          <Space size="middle">
            <DeleteProductInCart productId={produit?.product?.Reference} />
          </Space>
        );
      },
    },
  ];

  return cart.length ? (
    <div className="cart-page-container">
      <Row gutter={32} style={{ margin: viewportWidth < 400 ? 0 : "0 -16px" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={18}
          style={{ padding: viewportWidth < 400 ? 0 : "0 16px" }}>
          <div>
            <Table
              rowKey={(produit) => produit?.product?.Reference}
              pagination={false}
              columns={columns}
              footer={() => (
                <div>
                  <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                    {t("Optiques")}:{" "}
                    <span style={{ fontWeight: "normal" }}>{detailsPanier.optiques}</span>
                  </p>
                  <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                    {t("Solaires")}:{" "}
                    <span style={{ fontWeight: "normal" }}>{detailsPanier.solaires}</span>
                  </p>
                  <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                    {t("Accessoires")}:{" "}
                    <span style={{ fontWeight: "normal" }}>{detailsPanier.accessoires}</span>
                  </p>
                </div>
              )}
              dataSource={cart}
            />
            <div>
              <Descriptions layout="horizontal" bordered>
                <Descriptions.Item
                  label="Total"
                  labelStyle={{ fontWeight: "700", fontSize: "1rem" }}
                  contentStyle={{ fontSize: "2rem", textAlign: "right" }}>
                  {totalCart}
                  {(idDevise && idDevise === 3) || (idDevise && idDevise === 14) ? "$" : "€"}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
          {user?.repId === 107 || user?.repId === 105 ? (
            <SelectRepresentant usRepresentants={usRepresentants} onRepChange={onRepChange} />
          ) : (
            ""
          )}
          <SelectClientOnCart
            clients={clients}
            onClientChange={handleClientChange}
            setClient={setClient}
            waitingOrderClient={clientValue}
          />
          <DatePickerCustom onDeliveryDateChange={handleDeliveryDateChange} />
          <CommentsOnCart onCommentChange={handleCommentChange} waitingOrderComment={comValue} />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <ValiderCommande commande={commande} user={user} selectedRepObject={selectedRepObject} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <SaveOrderInIndexedDB
            commande={commande}
            user={user}
            selectedRepObject={selectedRepObject}
          />
        </Col>
      </Row>
    </div>
  ) : (
    <Empty description={<span>{t("Votre panier est vide")}</span>} />
  );
};

export default CartPage;
