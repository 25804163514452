import { useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { Button, Card, Col } from "antd";
import { ClockCircleTwoTone } from "@ant-design/icons";
import "./dashboard-agent.css";
import ModalAgentFacturesImpayees from "./ModalAgentFacturesImpayees";
import { useTranslation } from "react-i18next";

const FacturesImpayees = () => {
  const [isModalFacturesImpayeesOpen, setIsModalFacturesImpayeesOpen] = useState(false);
  const { totalFacturesImpayesByRepresentant, viewportWidth } = useContext(MainContext);

  useEffect(() => {
    console.log("totalFacturesImpayesByRepresentant: ", totalFacturesImpayesByRepresentant);
  }, [totalFacturesImpayesByRepresentant]);

  const { t } = useTranslation();

  const showModalFacturesImpayees = () => {
    setIsModalFacturesImpayeesOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalFacturesImpayeesOpen(false);
  };

  return (
    <>
      <Col xs={24} sm={12} md={12} lg={8} xl={8}>
        <Card
          title={
            viewportWidth < 1181 ? (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0 }}>{t("IMPAYÉS")}</p>{" "}
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <ClockCircleTwoTone twoToneColor="#EA2027" />
                </span>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <p style={{ margin: 0 }}>{t("FACTURES IMPAYÉES (TOTAL)")}</p>
                <span style={{ position: "absolute", top: 0, right: 0 }}>
                  <ClockCircleTwoTone twoToneColor="#EA2027" />
                </span>
              </div>
            )
          }
          style={{ height: "100%" }}
          bodyStyle={{
            padding: "16px 4px",
            textAlign: "center",
            fontSize: "2rem",
          }}>
          {totalFacturesImpayesByRepresentant?.montantTotalImpaye &&
          totalFacturesImpayesByRepresentant?.montantTotalImpaye > 0 ? (
            <>
              <p style={{ margin: 0 }}>
                {parseFloat(totalFacturesImpayesByRepresentant?.montantTotalImpaye)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )}
                €
              </p>
              <Button type="ghost" onClick={showModalFacturesImpayees}>
                {t("Plus d'infos")}
              </Button>
            </>
          ) : totalFacturesImpayesByRepresentant?.montantTotalImpaye == 0 ? (
            <>
              <p style={{ margin: 0 }}>0€</p>
            </>
          ) : (
            <Loader />
          )}
          <ModalAgentFacturesImpayees
            open={isModalFacturesImpayeesOpen}
            onOk={handleCloseModal}
            factures={totalFacturesImpayesByRepresentant?.facturesImpayes}
            viewportWidth={viewportWidth}
          />
        </Card>
      </Col>
    </>
  );
};

export default FacturesImpayees;
