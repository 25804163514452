import { useEffect } from "react";
import styles from "./defaultPage.module.css";

const DefaultPage = () => {
  useEffect(() => {
    console.log("TEST ENVIRONMENT TEST ALLO !!!");
  }, []);

  return (
    <div className={styles.defaultPageWrapper}>
      <h2>Welcome to</h2>
      <br />
      <h1 className={styles.titleDefaultPage}>MNB SALES</h1>
    </div>
  );
};

export default DefaultPage;
