import { useContext, useEffect, useState } from "react";
import CartContext from "../../contexts/CartContext";
import toast from "react-hot-toast";
import AuthContext from "../../contexts/AuthContext";
import { formatDateToW100 } from "../../utils/functions";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PriceContext from "../../contexts/PriceContext";

const SaveOrderInIndexedDBBtn = {
  backgroundColor: "#1890ff",
  width: "100%",
  padding: "12px",
  border: "none",
  color: "white",
  fontSize: "1.4rem",
  fontWeight: "bold",
  cursor: "pointer",
};

const SaveOrderInIndexedDB = ({ commande, user, selectedRepObject = null }) => {
  const [lignesList, setLignesList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { clearCart } = useContext(CartContext);
  const { accessTokenJwt } = useContext(AuthContext);
  const { idDevise, categorieTarifaire } = useContext(PriceContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (commande && commande.client) {
      createLignesDocument(commande);
    }
  }, [commande]);

  /**Créer les lignes du bon de commande */
  const createLignesDocument = (commande) => {
    let lignesList = [];
    commande?.cart?.forEach((product) => {
      lignesList.push({
        __type: "LigneArticle:http://www.proconsult.lu/WebServices100",
        TypeDocument: 1,
        // IdGamme1: 0,
        Quantite: product.qty,
        RefArticle: product.product.Reference,
        Intitule: product.product.Intitule,
        EstValorise: 1,
        PrixUnitaire:
          idDevise !== 0
            ? product?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixDevise
            : product?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixVente,
        PrixUnitaireDevise:
          idDevise !== 0
            ? product?.product?.CategoriesTarifaire?.[categorieTarifaire]?.PrixDevise
            : 0,
        // PrixUnitaireBC: product.product.PrixVente,
        // PrixUnitaireTTC: product.product.PrixVente,
        // MontantHT: product.product.PrixVente,
        // MontantTTC: product.product.PrixVente,
        CodeTaxe1: commande?.client[0]?.IdCategorieComptable === 1 ? "C04" : null,
        Taux1: commande?.client[0]?.IdCategorieComptable === 1 ? 20.0 : 0,
        RemiseType1: commande?.client[0]?.TauxRemise !== 0 ? 1 : 0,
        RemiseValeur1: commande?.client[0]?.TauxRemise !== 0 ? commande?.client[0]?.TauxRemise : 0,
        ...product,
      });
    });
    setLignesList(lignesList);
  };

  const formatOrder = (commande, id) => {
    const body = JSON.stringify({
      document: {
        __type: "DocumentVente:http://www.proconsult.lu/WebServices100",
        TypeDocument: 1,
        Domaine: 0,
        Date: formatDateToW100(),
        Reference: id,
        NumeroTiers: commande?.clientId,
        NumeroTiersPayeur: commande?.clientId,
        Intitule: commande?.client[0]?.Intitule,
        Adresse: commande?.client[0]?.Adresse,
        Ville: commande?.client[0]?.Ville,
        CodePostal: commande?.client[0]?.CodePostal,
        Pays: commande?.client[0]?.Pays,
        Email: commande?.client[0]?.Email,
        Telephone: commande?.client[0]?.Telephone,
        Representant:
          selectedRepObject !== null
            ? `${selectedRepObject?.Prenom} ${selectedRepObject?.Nom}`
            : `${user?.firstname} ${user?.lastname}`,
        RepresentantEmail: selectedRepObject !== null ? selectedRepObject.Email : user?.mail,
        TotalCart: commande?.totalCart,
        // Statut: 0,
        // Provenance: 0,
        DateLivraison: formatDateToW100(commande?.deliveryDate),
        // DateLivraisonRealisee: "/Date(-2208992400000+0100)/",
        // DateExpedition: "/Date(-2208992400000+0100)/",
        // SectionAnalytique: "",
        // Contact: "",
        // NbFactures: 1,
        // NbColis: 1,
        TauxEscompte: commande?.client[0]?.TauxEscompte,
        // Langue: 0,
        // Donnee1: "",
        // Donnee2: "",
        // Donnee3: "",
        // Donnee4: "",
        // EstImprime: false,
        // EstTransfere: false,
        // EstCloture: false,
        // EstReliquat: false,
        IdSouche: 3,
        IdCollaborateur: selectedRepObject !== null ? selectedRepObject.Id : user?.repId,
        IdDepot: 1,
        // IdAdresseLivraison: 0,
        IdModeExpedition: commande?.client[0]?.IdModeExpedition,
        // IdConditionLivraison: 0,
        IdCategorieComptable: commande?.client[0]?.IdCategorieComptable,
        IdCategorieTarifaire: commande?.client[0]?.IdCategorieTarifaire,
        IdModeleReglement: commande?.client[0]?.IdModeleReglement,
        IdDevise: commande?.client[0]?.IdDevise,
        // CoursDevise: 0,
        // FraisExpedition: 0,
        // TypeFraisExpedition: 0,
        // TypePrixFraisExpedition: 0,
        // FrancoDePort: 0,
        // TypeFrancoPort: 0,
        // TypePrixFrancoPort: 0,
        ExpeditionCodeTaxe1: commande?.client[0]?.IdCategorieComptable === 1 ? "C04" : null,
        // ExpeditionCodeTaxe2: null,
        // ExpeditionCodeTaxe3: null,
        ExpeditionTaux1: commande?.client[0]?.IdCategorieComptable === 1 ? 20.0 : 0,
        // ExpeditionTaux2: 0,
        // ExpeditionTaux3: 0,
        InfosLibres: [{ Name: "Commentaires", Value: `${commande.commentaire}` }],
      },
      ligneList: lignesList,
      useSageProcess: false,
    });

    return body;
  };

  const saveCommandToIndexedDB = async (commande) => {
    const dbRequest = indexedDB.open("postRequestsDB", 1);

    // Événement déclenché si une mise à jour de base de données est nécessaire
    dbRequest.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("pending")) {
        db.createObjectStore("pending");
      }
    };

    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const tx = db.transaction(["pending"], "readwrite");
      const store = tx.objectStore("pending");
      const id = new Date().getTime();

      // Sauvegardez la commande dans l'object store
      store.put(
        {
          url: `${process.env.REACT_APP_API_URL}/documents/bon-commande/create`,
          headers: {
            Authorization: `Bearer ${accessTokenJwt}`,
          },
          body: formatOrder(commande, id),
        },
        id
      );

      tx.oncomplete = () => {
        toast.success("Commande ajouté à la file d'attente");
        setTimeout(() => {
          window.history.back();
          // clearCart();
        }, 1000);
      };

      tx.onerror = (event) => {
        toast.error("Une erreur s'est produite lors de la sauvegarde de la commande");
      };
    };

    dbRequest.onerror = (event) => {
      toast.error("Une erreur s'est produite lors de l'ouverture de la base de données");
    };
  };

  const handleClick = () => {
    if (!commande || !commande.cart || commande.cart.length === 0) {
      toast.error("Votre panier est vide !");
      return;
    }

    if (!commande.clientId) {
      toast.error(t("Veuillez choisir un client"));
      return;
    }

    if (commande.commentaire.length > 300) {
      toast.error(t("Veuillez ne pas dépasser 300 caractères dans le commentaire"));
      return;
    }

    setButtonDisabled(true);

    saveCommandToIndexedDB(commande);
    clearCart();
  };

  return (
    <button onClick={handleClick} style={SaveOrderInIndexedDBBtn} disabled={buttonDisabled}>
      {t("File d'attente")} <ReloadOutlined />
    </button>
  );
};

export default SaveOrderInIndexedDB;
