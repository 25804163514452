import { Card } from "antd";
import { PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { PastilleDispo } from "../pastille-dispo";
import "./produit.css";
import CartContext from "../../contexts/CartContext";
import PriceContext from "../../contexts/PriceContext";

const Produit = ({ produit, devise, user }) => {
  const { cart, addProductToCart, deleteCartItem } = useContext(CartContext);
  const { categorieTarifaire } = useContext(PriceContext);
  const [stock, setStock] = useState(null);
  const [loadingStock, setLoadingStock] = useState(true);
  const [error, setError] = useState(null);
  const [cartQty, setCartQty] = useState(0);

  useEffect(() => {
    setLoadingStock(true);
    setStock(produit.QteStock - produit.QteReserve);
    setLoadingStock(false);
  }, [produit]);

  useEffect(() => {
    getCartQtyByProduct(cart, produit);
  }, [cart]);

  const getCartQtyByProduct = (cart, product) => {
    if (cart.length < 1) {
      setCartQty(0);
      return;
    }

    const isInCart = cart.filter((item) => item.product.Reference === product.Reference);

    if (isInCart.length) {
      setCartQty(isInCart[0].qty);
      // console.log("isInCart: ", isInCart);
    } else {
      setCartQty(0);
    }
  };

  const onDeleteBtnClick = (product) => {
    deleteCartItem(product.Reference);
  };

  const onAddBtnClick = (product) => {
    addProductToCart(product, 1, stock);
  };

  return (
    <Card
      title={
        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          <p style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{produit?.Intitule}</p>
        </div>
      }
      hoverable={true}
      cover={
        <img
          alt="produit"
          className="product-page__product-image"
          src={`https://test.mnbsales.com/images/${produit?.RefArticle}.webp`}
          // Mode développement => Evite la tentative de récupération de l'image en boucle
          // onError={(e) => {
          //   e.target.onerror = null;
          //   e.target.src = "/images/no-photo.webp";
          // }}
        />
      }
      style={{
        padding: 8,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
      actions={
        user?.role !== "guest"
          ? [
              <PastilleDispo stockProduct={stock} loadingStock={loadingStock} error={error} />,
              <span style={{ fontSize: "1.2rem" }}>
                {/* {produit?.PrixVente} */}
                {produit?.CategoriesTarifaire[categorieTarifaire].IdDevise !== 0
                  ? produit?.CategoriesTarifaire[categorieTarifaire].PrixDevise
                  : produit?.CategoriesTarifaire[categorieTarifaire].PrixVente}
                {(devise && devise === 3) || (devise && devise === 14) ? "$" : "€"}
              </span>,
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "nowrap",
                }}>
                {cartQty > 0 ? (
                  <>
                    <button
                      className="qtyBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        onDeleteBtnClick(produit);
                      }}>
                      <DeleteOutlined className="icon-moins" />
                    </button>
                    <p style={{ margin: 0 }}>{cartQty}</p>
                    <button
                      className="qtyBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        onAddBtnClick(produit);
                      }}>
                      <PlusSquareOutlined className="icon-plus" />
                    </button>
                  </>
                ) : (
                  <>
                    <div></div>
                    <p style={{ margin: 0 }}>{cartQty}</p>
                    <button
                      className="qtyBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        onAddBtnClick(produit);
                      }}>
                      <PlusSquareOutlined className="icon-plus" />
                    </button>
                  </>
                )}
              </div>,
            ]
          : [<PastilleDispo stockProduct={stock} loadingStock={loadingStock} error={error} />]
      }></Card>
  );
};

export default Produit;
