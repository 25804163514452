import { Button, Card } from "antd";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ModalImpayes } from "../modal-client-impayes";

const DashboardClientImpayeNMoinsUn = ({ montantImpaye, tabFacturesImpayes }) => {
  const { t } = useTranslation();
  const [isModalImpayesOpen, setIsModalImpayesOpen] = useState(false);
  const totalMontantImpaye = Number(montantImpaye);

  const showModalImpayes = () => {
    setIsModalImpayesOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalImpayesOpen(false);
  };

  return (
    <Card
      title={t("RESTANT DÛ (N-1)")}
      style={{ height: "100%" }}
      bodyStyle={{ padding: "16px 4px" }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        {!totalMontantImpaye === null ? (
          <Loader />
        ) : totalMontantImpaye && totalMontantImpaye > 0 ? (
          <p
            style={{
              fontSize: "2rem",
              margin: 0,
              color: "red",
            }}>
            {totalMontantImpaye.toFixed(2)} €
          </p>
        ) : (
          <p
            style={{
              fontSize: "2rem",
              margin: 0,
              color: "green",
            }}>
            {totalMontantImpaye.toFixed(2)} €
          </p>
        )}
        {tabFacturesImpayes && tabFacturesImpayes.length ? (
          <Button type="ghost" onClick={showModalImpayes}>
            {t("Plus d'infos")}
          </Button>
        ) : (
          ""
        )}
      </div>
      <ModalImpayes
        tabFacturesImpayes={tabFacturesImpayes}
        open={isModalImpayesOpen}
        onOk={handleCloseModal}
      />
    </Card>
  );
};

export default DashboardClientImpayeNMoinsUn;
